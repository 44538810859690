import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { Container } from "reactstrap"
import SEO from "../components/seo"
// import blogAuthor from "../../static/assets/darlene.jpg"
// const slugify = require("slugify")

// import mouseWheel from "../images/mouse-wheel.gif"

const Blogs = ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <SEO
        title="Latest Posts"
        description={data.site.siteMetadata.description}
      />
      {/* <div className="container-fluid hero-section">
        <div className="row">
          <div className="col-md-5 vertical-align d-none-mobile">
            <div className="brandstamp">
              <h1>Our Blog</h1>
            </div>
          </div>
          <div className="col-md-7 hero-img vertical-align">
            <div className="brandstamp-mobile text-white">
              <h1>Our Blog</h1>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="col-lg-12 text-center scroll-down">
        <img className="mouse-wheel" src={mouseWheel} alt="Scroll Down" />
      </div> */}

      <div className="blog-section">
        <Container>
          <div className="row justify-content-between">
            <div className="w-100">
              <iframe
                src="https://www.simplifyingthemarket.com/en/?a=312295-cd981b5955261e0907c46fab36efe9b3"
                title="Simplifying The Market Blog"
                width="100%"
                height="1000"
              ></iframe>
            </div>
            <div className="col-lg-12">
              <div className="blog-item">
                <div className="row">
                  {data.allMarkdownRemark.edges.map(blog => (
                    <div
                      className="col-lg-4 col-md-6 col-sm-12 mt-5"
                      key={blog.node.id}
                      style={{ background: "#fff" }}
                    >
                      <Link
                        to={blog.node.fields.slug}
                        // to="/loan-programs/va-home-loans"
                        className="lprog-wrapper"
                      >
                        <div
                          className="card apply-container p-0"
                          style={{ border: "none" }}
                        >
                          <div
                            className="blog-img-box"
                            style={{
                              height: "12rem",
                              backgroundImage: `url(/${
                                blog.node.frontmatter.banner !== null
                                  ? blog.node.frontmatter.banner
                                  : "https://source.unsplash.com/user/joshhild/500x500"
                              })`,
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                            }}
                          />
                          <div className="card-body">
                            <h4 className="card-title mt-3 mb-3">
                              {blog.node.frontmatter.title}
                            </h4>
                            {/* <p
                              className="card-text"
                              style={{
                                width: "100%",
                                height: "auto",
                                color: "#5f5f71",
                              }}
                            >
                              {blog.node.frontmatter.excerpt.length >= 100
                                ? blog.node.frontmatter.excerpt.substring(
                                    0,
                                    100
                                  ) + "..."
                                : blog.node.frontmatter.excerpt}
                            </p> */}
                            <div className="footer-blog-wrap">
                              <div className="row align-items-center">
                                <div className="col-lg-12">
                                  <div className="author-face mt-3 d-flex justify-content-between align-items-center">
                                    {/* <img
                                      src={blogAuthor}
                                      alt="BLog Author"
                                      className="img-fluid rounded-circle float-left mr-3"
                                      width={60}
                                    /> */}
                                    <p className="m-0">
                                      {/* <span className="mr-3">
                                        by: {blog.node.frontmatter.author}
                                      </span> */}
                                      <span className="text-muted small">
                                        <i className="fa fa-calendar-o pr-1" />
                                        {blog.node.frontmatter.publishedDate}
                                      </span>
                                    </p>
                                    <span
                                      to={blog.node.fields.slug}
                                      className="ml-3"
                                    >
                                      Read Now
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* <div class="col-md-4 mb-4">
              <div class="side-content">
                <h6 class="text-uppercase text-muted">Categories</h6>
                <ul class="list-unstyled">
                  {data.allSanityPost.group.map(cat => (
                    <li key={cat.fieldValue}>
                      <Link
                        class="text-body font-weight-bold"
                        to={slugify(cat.fieldValue.toLowerCase())}
                      >
                        {cat.fieldValue}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div class="side-content">
                <h6 class="text-uppercase text-muted">Keep Up-to-Date</h6>
                <p class="small">
                  Get our latest news and updates straight to your inbox. Enter
                  your email address to subscribe:
                </p>
                <form>
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="email"
                      id="email"
                      required=""
                    />
                    <label htmlFor="email">Email address</label>
                  </div>
                  <div class="form-group mb-4">
                    <button class="btn btn-primary" type="submit">
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div> */}
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default Blogs

export const blogQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: frontmatter___publishedDate, order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            banner
            publishedDate(formatString: "MMMM DD, YYYY")
            excerpt
            author
            metaTitle
            metaKeywords
            metaDescription
          }
        }
      }
    }
  }
`
